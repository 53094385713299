import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A router's request lifecycle has three major services:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Router service`}</strong>{` - Handles an incoming request before it is parsed. Works within a context of opaque bytes.`}</li>
      <li parentName="ul"><strong parentName="li">{`Supergraph service`}</strong>{` - Handles a request after it has been parsed and before it is sent to the subgraph. Works within a GraphQL context.`}</li>
      <li parentName="ul"><strong parentName="li">{`Subgraph service`}</strong>{` - Handles a request after it has been sent to the subgraph. Works within a GraphQL context.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      