import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpansionPanel = makeShortcode("ExpansionPanel");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ExpansionPanel title="Adding SQLite with Swift Package Manager" mdxType="ExpansionPanel">
      <p>{`Add the following dependency to your target's `}<inlineCode parentName="p">{`dependencies`}</inlineCode>{` in your `}<inlineCode parentName="p">{`Package.swift`}</inlineCode>{` file:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-swift"
        }}>{`.product(name: "ApolloSQLite", package: "Apollo"),
`}</code></pre>
    </ExpansionPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      