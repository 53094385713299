import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`For Redis TLS connections, you can set up a client certificate or override the root certificate authority by configuring `}<inlineCode parentName="p">{`tls`}</inlineCode>{` in your router's `}<a parentName="p" {...{
        "href": "https://www.apollographql.com/docs/router/overview/#yaml-config-file"
      }}>{`YAML config file`}</a>{`. For example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`apq:
  router:
    cache:
      redis:
        urls: [ "rediss://redis.example.com:6379" ]
        #highlight-start
        tls:
          certificate_authorities: \${file./path/to/ca.crt}
          client_authentication:
            certificate_chain: \${file./path/to/certificate_chain.pem}
            key: \${file./path/to/key.pem}
        #highlight-end
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      