import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Note = makeShortcode("Note");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Note mdxType="Note">
      <p>{`The Apollo Router Core source code and all its distributions are made available under the `}<a parentName="p" {...{
          "href": "https://www.apollographql.com/docs/resources/elastic-license-v2-faq/#can-i-extend-or-modify-the-gateway-or-router-by-creating-plugins"
        }}>{`Elastic License v2.0 (ELv2) license`}</a>{`.`}</p>
    </Note>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      