import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The `}<inlineCode parentName="p">{`ApolloProvider`}</inlineCode>{` component leverages `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/context.html"
      }}>{`React's Context API`}</a>{` to make a configured Apollo Client instance available throughout a React component tree. This component can be imported directly from the `}<inlineCode parentName="p">{`@apollo/react-common`}</inlineCode>{` package where it lives, or from one of the `}<inlineCode parentName="p">{`@apollo/react-hooks`}</inlineCode>{`, `}<inlineCode parentName="p">{`@apollo/react-components`}</inlineCode>{`, and `}<inlineCode parentName="p">{`@apollo/react-hoc`}</inlineCode>{` packages.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { ApolloProvider } from '@apollo/react-hooks';
`}</code></pre>
    <h3 {...{
      "id": "props"
    }}><a parentName="h3" {...{
        "href": "#props"
      }}>{`Props`}</a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Option`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`client`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ApolloClient`}{`<`}{`TCache`}{`>`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An `}<inlineCode parentName="td">{`ApolloClient`}</inlineCode>{` instance.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "example"
    }}><a parentName="h3" {...{
        "href": "#example"
      }}>{`Example`}</a></h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`ReactDOM.render(
  <ApolloProvider client={client}>
    <MyRootComponent />
  </ApolloProvider>,
  document.getElementById('root'),
);
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      