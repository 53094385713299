import SPMInstallationPanel from "../../../gatsby-source-git/apollographql/apollo-ios/ios/v0-legacy/docs/shared/spm-installation-panel.mdx";
import SPMRunScriptPanel from "../../../gatsby-source-git/apollographql/apollo-ios/ios/v0-legacy/docs/shared/spm-run-script-panel.mdx";
import PodsInstallationPanel from "../../../gatsby-source-git/apollographql/apollo-ios/ios/v0-legacy/docs/shared/pods-installation-panel.mdx";
import PodsRunScriptPanel from "../../../gatsby-source-git/apollographql/apollo-ios/ios/v0-legacy/docs/shared/pods-run-script-panel.mdx";
import CarthageInstallationPanel from "../../../gatsby-source-git/apollographql/apollo-ios/ios/v0-legacy/docs/shared/carthage-installation-panel.mdx";
import CarthageRunScriptPanel from "../../../gatsby-source-git/apollographql/apollo-ios/ios/v0-legacy/docs/shared/carthage-run-script-panel.mdx";
import * as React from 'react';
export default {
  SPMInstallationPanel,
  SPMRunScriptPanel,
  PodsInstallationPanel,
  PodsRunScriptPanel,
  CarthageInstallationPanel,
  CarthageRunScriptPanel,
  React
};