import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const EnterpriseFeature = makeShortcode("EnterpriseFeature");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <EnterpriseFeature mdxType="EnterpriseFeature">
      <p>{`This directive is an `}<a parentName="p" {...{
          "href": "/docs/router/enterprise-features"
        }}>{`Enterprise feature`}</a>{` of the GraphOS Router and requires an organization with a `}<a parentName="p" {...{
          "href": "https://www.apollographql.com/pricing/"
        }}>{`GraphOS Enterprise plan`}</a>{`. If your organization doesn't have an Enterprise plan, you can test it out by signing up for a free `}<a parentName="p" {...{
          "href": "/docs/graphos/org/plans/#enterprise-trials"
        }}>{`Enterprise trial`}</a>{`.`}</p>
    </EnterpriseFeature>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      