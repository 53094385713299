import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpansionPanel = makeShortcode("ExpansionPanel");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ExpansionPanel title="Swift Package Manager Run Script" mdxType="ExpansionPanel">
      <blockquote>
        <p parentName="blockquote"><strong parentName="p">{`Note:`}</strong>{` If your Derived Data is in a custom location, go back and use the `}<a parentName="p" {...{
            "href": "./swift-scripting"
          }}>{`Swift Scripting`}</a>{` method instead. This script relies on Derived Data being in the default location. Swift Scripting doesn't rely on Derived Data at all.`}</p>
      </blockquote>
      <p>{`If you're using Xcode 11 or higher, SPM checks out the appropriate build script along with the rest of the files when it checks out the repo. Add the following to your Run Script build phase:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`# Don't run this during index builds
if [ $ACTION = "indexbuild" ]; then exit 0; fi

# Go to the build root and search up the chain to find the Derived Data Path where the source packages are checked out.
DERIVED_DATA_CANDIDATE="\${BUILD_ROOT}"

while ! [ -d "\${DERIVED_DATA_CANDIDATE}/SourcePackages" ]; do
  if [ "\${DERIVED_DATA_CANDIDATE}" = / ]; then
    echo >&2 "error: Unable to locate SourcePackages directory from BUILD_ROOT: '\${BUILD_ROOT}'"
    exit 1
  fi

  DERIVED_DATA_CANDIDATE="$(dirname "\${DERIVED_DATA_CANDIDATE}")"
done

# Grab a reference to the directory where scripts are checked out
SCRIPT_PATH="\${DERIVED_DATA_CANDIDATE}/SourcePackages/checkouts/apollo-ios/scripts"

if [ -z "\${SCRIPT_PATH}" ]; then
    echo >&2 "error: Couldn't find the CLI script in your checked out SPM packages; make sure to add the framework to your project."
    exit 1
fi

cd "\${SRCROOT}/\${TARGET_NAME}"
"\${SCRIPT_PATH}"/run-bundled-codegen.sh codegen:generate --target=swift --includes=./**/*.graphql --localSchemaFile="schema.json" API.swift
`}</code></pre>
      <blockquote>
        <p parentName="blockquote"><strong parentName="p">{`Note:`}</strong>{` If you try to use this with command line SPM, when you regenerate your `}<inlineCode parentName="p">{`xcodeproj`}</inlineCode>{` this build script will get wiped out. We strongly recommend using Xcode 11's built-in SPM handling instead of the command line because of this.`}</p>
      </blockquote>
    </ExpansionPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      