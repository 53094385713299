import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpansionPanel = makeShortcode("ExpansionPanel");
const ExpansionPanelList = makeShortcode("ExpansionPanelList");
const ExpansionPanelListItem = makeShortcode("ExpansionPanelListItem");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ExpansionPanel title="Carthage Installation" mdxType="ExpansionPanel">
      <ExpansionPanelList mdxType="ExpansionPanelList">
        <ExpansionPanelListItem mdxType="ExpansionPanelListItem">
          <h4 {...{
            "id": "set-up-your-cartfile"
          }}><a parentName="h4" {...{
              "href": "#set-up-your-cartfile"
            }}>{`Set up your `}<inlineCode parentName="a">{`Cartfile`}</inlineCode></a></h4>
          <p>{`Add `}<inlineCode parentName="p">{`github "apollographql/apollo-ios"`}</inlineCode>{` to your Cartfile.`}</p>
        </ExpansionPanelListItem>
        <ExpansionPanelListItem mdxType="ExpansionPanelListItem">
          <h4 {...{
            "id": "check-out-and-build-dependencies"
          }}><a parentName="h4" {...{
              "href": "#check-out-and-build-dependencies"
            }}>{`Check out and build dependencies`}</a></h4>
          <p>{`Run `}<inlineCode parentName="p">{`carthage update --use-xcframeworks --platform ios`}</inlineCode>{` (or `}<inlineCode parentName="p">{`--platform ios,macos`}</inlineCode>{` to build both Mac and iOS).`}</p>
          <blockquote>
            <p parentName="blockquote"><strong parentName="p">{`Note:`}</strong>{` There's an issue with the way Carthage uses Lipo in the Xcode 12 GM. Please `}<inlineCode parentName="p">{`cd`}</inlineCode>{` into `}<inlineCode parentName="p">{`[YourProject]/Carthage/Checkouts/apollo-ios/scripts`}</inlineCode>{` and then run `}<inlineCode parentName="p">{`./carthage-build-workaround.sh`}</inlineCode>{` to resolve this build issue.`}</p>
          </blockquote>
        </ExpansionPanelListItem>
        <ExpansionPanelListItem mdxType="ExpansionPanelListItem"> 
          <h4 {...{
            "id": "add-built-frameworks-to-your-project"
          }}><a parentName="h4" {...{
              "href": "#add-built-frameworks-to-your-project"
            }}>{`Add built frameworks to your project`}</a></h4>
          <p>{`Drag and drop `}<inlineCode parentName="p">{`Apollo.framework`}</inlineCode>{` from the appropriate `}<inlineCode parentName="p">{`Carthage/Build/iOS`}</inlineCode>{` or `}<inlineCode parentName="p">{`Carthage/Build/Mac`}</inlineCode>{` folder to the `}<strong parentName="p">{`Embedded Binaries`}</strong>{` section of your application target's `}<strong parentName="p">{`General`}</strong>{` settings tab. This should also cause them to appear in the `}<strong parentName="p">{`Linked Frameworks And Libraries`}</strong>{` section automatically.`}</p>
          <ul>
            <li parentName="ul">{`To include the `}<inlineCode parentName="li">{`ApolloSQLite`}</inlineCode>{` library, also drag `}<inlineCode parentName="li">{`ApolloSQLite.framework`}</inlineCode>{` and `}<inlineCode parentName="li">{`SQLite.framework`}</inlineCode>{` to this area.`}</li>
            <li parentName="ul">{`To include the `}<inlineCode parentName="li">{`ApolloWebSocket`}</inlineCode>{` library, also drag `}<inlineCode parentName="li">{`ApolloWebSocket.framework`}</inlineCode>{` and `}<inlineCode parentName="li">{`Starscream.framework`}</inlineCode>{` to this area.`}</li>
          </ul>
        </ExpansionPanelListItem>
        <ExpansionPanelListItem mdxType="ExpansionPanelListItem">
          <h4 {...{
            "id": "work-around-carthage-submission-bug"
          }}><a parentName="h4" {...{
              "href": "#work-around-carthage-submission-bug"
            }}>{`Work around Carthage submission bug`}</a></h4>
          <p>{`On your application target's `}<strong parentName="p">{`Build Phases`}</strong>{` settings tab, click the `}<strong parentName="p">{`+`}</strong>{` icon and choose `}<strong parentName="p">{`New Run Script Phase`}</strong>{`. Create a Run Script in which you specify your shell (e.g., `}<inlineCode parentName="p">{`bin/sh`}</inlineCode>{`) and add the following contents to the script area below the shell:`}</p>
          <pre><code parentName="pre" {...{
              "className": "language-sh"
            }}>{`/usr/local/bin/carthage copy-frameworks
`}</code></pre>
          <p>{`Then, add the paths to the frameworks you want to use under `}<strong parentName="p">{`Input Files`}</strong>{`, e.g.:`}</p>
          <pre><code parentName="pre" {...{}}>{`$(SRCROOT)/Carthage/Build/iOS/Apollo.framework
`}</code></pre>
          <p>{`Again, if you're adding `}<inlineCode parentName="p">{`ApolloSQLite`}</inlineCode>{` or `}<inlineCode parentName="p">{`ApolloWebSocket`}</inlineCode>{`, please make sure to add the other frameworks you added as Input Files.`}</p>
          <p>{`This script works around an `}<a parentName="p" {...{
              "href": "http://www.openradar.me/radar?id=6409498411401216"
            }}>{`App Store submission bug`}</a>{` triggered by universal binaries and ensures that necessary bitcode-related files and dSYMs are copied when archiving.`}</p>
        </ExpansionPanelListItem>
        <ExpansionPanelListItem mdxType="ExpansionPanelListItem">
          <p>{`You're done!`}</p>
        </ExpansionPanelListItem>
      </ExpansionPanelList>
    </ExpansionPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      