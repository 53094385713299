import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Apollo Android requires your GraphQL server's schema as a `}<inlineCode parentName="p">{`schema.json`}</inlineCode>{` file. You can obtain the contents of this file by running an introspection query on your server.`}</p>
    <p><strong parentName="p">{`Note`}</strong>{`: If you don't have a GraphQL server yet, you can use the server from the `}<a parentName="p" {...{
        "href": "https://www.apollographql.com/docs/android/tutorial/00-introduction/"
      }}>{`tutorial`}</a>{`: `}<a parentName="p" {...{
        "href": "https://apollo-fullstack-tutorial.herokuapp.com/graphql"
      }}>{`https://apollo-fullstack-tutorial.herokuapp.com/graphql`}</a>{`.`}</p>
    <p>{`The Apollo Gradle plugin exposes a `}<inlineCode parentName="p">{`downloadApolloSchema`}</inlineCode>{` task to help you obtain your schema. Provide this task your server's GraphQL endpoint and the output location for the `}<inlineCode parentName="p">{`schema.json`}</inlineCode>{` file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash:title=(shell)"
      }}>{`./gradlew downloadApolloSchema \\
  --endpoint="https://your.domain/graphql/endpoint" \\
  --schema="src/main/graphql/com/example/schema.json"
`}</code></pre>
    <p>{`If your GraphQL endpoint requires authentication, you can pass custom HTTP headers:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash:title=(shell)"
      }}>{`./gradlew downloadApolloSchema \\
  --endpoint="https://your.domain/graphql/endpoint" \\
  --schema="app/src/main/graphql/com/example/schema.json" \\
  --header="Authorization: Bearer $TOKEN"
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      