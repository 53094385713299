import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><undefined parentName="p"><span {...{
          "role": "img",
          "aria-label": "warning"
        }}>{`⚠️`}</span>{` Apollo doesn't recommend creating native plugins for the Apollo Router Core or GraphOS Router, for the following reasons:`}</undefined></p>
    <ul>
      <li parentName="ul">{`Native plugins require familiarity with programming in Rust.`}</li>
      <li parentName="ul">{`Native plugins require compiling a custom router binary from source, which can introduce unexpected behavior in your router that's difficult to diagnose and support.`}</li>
    </ul>
    <p>{`Instead, for most router customizations, Apollo recommends creating either a `}<a parentName="p" {...{
        "href": "https://www.apollographql.com/docs/router/customizations/rhai/"
      }}>{`Rhai script`}</a>{` or an `}<a parentName="p" {...{
        "href": "https://www.apollographql.com/docs/router/customizations/coprocessor"
      }}>{`external coprocessor`}</a>{`. Both of these customizations are supported by Apollo and provide strong separation of concerns and fault isolation.`}</p>
    <p>{`If you must create a native plugin, please `}<a parentName="p" {...{
        "href": "https://github.com/apollographql/router/issues"
      }}>{`open a GitHub issue`}</a>{`, and Apollo can investigate adding the custom capability to the stock router binary.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      