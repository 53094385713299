import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ol>
      <li parentName="ol">{`Create a directory for your GraphQL files: `}<inlineCode parentName="li">{`src/main/graphql/com/example/`}</inlineCode></li>
      <li parentName="ol">{`Add your `}<inlineCode parentName="li">{`schema.json`}</inlineCode>{` to the directory: `}<inlineCode parentName="li">{`src/main/graphql/com/example/schema.json`}</inlineCode></li>
      <li parentName="ol">{`Put your query in a `}<inlineCode parentName="li">{`.graphql`}</inlineCode>{` file, next to the schema: `}<inlineCode parentName="li">{`src/main/graphql/com/example/LaunchDetails.graphql`}</inlineCode></li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-graphql",
        "metastring": "title=\"src/main/graphql/com/example/LaunchDetails.graphql\"",
        "title": "\"src/main/graphql/com/example/LaunchDetails.graphql\""
      }}>{`query LaunchDetails($id:ID!) {
  launch(id: $id) {
    id
    site
    mission {
      name
      missionPatch(size:LARGE)
    }
  }
}
`}</code></pre>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Build your project, this will generate the model`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      