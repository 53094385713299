import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { CodeBlock, CodeBlockProps } from "@apollo/chakra-helpers";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpansionPanelList = makeShortcode("ExpansionPanelList");
const ExpansionPanelListItem = makeShortcode("ExpansionPanelListItem");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <ExpansionPanelList mdxType="ExpansionPanelList">
      <ExpansionPanelListItem mdxType="ExpansionPanelListItem">
        <h4 {...{
          "id": "install-the-codegen-cli"
        }}><a parentName="h4" {...{
            "href": "#install-the-codegen-cli"
          }}>{`Install the Codegen CLI`}</a></h4>
        <>{props.installComponent}</>
      </ExpansionPanelListItem>
      <ExpansionPanelListItem mdxType="ExpansionPanelListItem">
        <h4 {...{
          "id": "initialize-the-code-generation-configuration"
        }}><a parentName="h4" {...{
            "href": "#initialize-the-code-generation-configuration"
          }}>{`Initialize the code generation configuration`}</a></h4>
        <p>{`The Codegen CLI uses a JSON file to configure the code generation engine. You can use the Codegen CLI's `}<inlineCode parentName="p">{`init`}</inlineCode>{` command to create this file with default values.`}</p>
        <p>{`From your project's root directory, run the following command with your customized values:`}</p>
        <CodeBlock code={props.cliPath + " init --schema-namespace ${MySchemaName} --module-type ${ModuleType}"} language="bash" showLineNumbers='true' mdxType="CodeBlock" />
        <ul>
          <li parentName="ul"><inlineCode parentName="li">{`\${MySchemaName}`}</inlineCode>{` provides a name for the namespace of your generated schema files.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`\${ModuleType}`}</inlineCode>{` configures how your generated schema types are included in your project.`}
            <blockquote parentName="li">
              <p parentName="blockquote">{`This is a crucial decision to make `}<strong parentName="p">{`before configuring code generation`}</strong>{`. To determine the right option for your project, see `}<a parentName="p" {...{
                  "href": "/docs/ios/project-configuration/intro"
                }}>{`Project Configuration`}</a>{`.`}</p>
              <p parentName="blockquote">{`To get started quickly, you can use the `}<inlineCode parentName="p">{`embeddedInTarget`}</inlineCode>{` option.
Using `}<inlineCode parentName="p">{`embeddedInTarget`}</inlineCode>{`, you must supply a target name using the `}<inlineCode parentName="p">{`--target-name`}</inlineCode>{` command line option.`}</p>
            </blockquote>
          </li>
        </ul>
        <p>{`Running this command creates an `}<inlineCode parentName="p">{`apollo-codegen-config.json`}</inlineCode>{` file.`}</p>
      </ExpansionPanelListItem>
      <ExpansionPanelListItem mdxType="ExpansionPanelListItem">
        <h4 {...{
          "id": "configure-code-generation-options"
        }}><a parentName="h4" {...{
            "href": "#configure-code-generation-options"
          }}>{`Configure code generation options`}</a></h4>
        <p>{`Open your `}<inlineCode parentName="p">{`apollo-codegen-config.json`}</inlineCode>{` file to start configuring code generation for your project.`}</p>
        <p>{`The default configuration will:`}</p>
        <ul>
          <li parentName="ul">{`Find all GraphQL schema files ending with the file extension `}<inlineCode parentName="li">{`.graphqls`}</inlineCode>{` within your project directory.`}</li>
          <li parentName="ul">{`Find all GraphQL operation and fragment definition files ending with the file extension `}<inlineCode parentName="li">{`.graphql`}</inlineCode>{` within your project directory.`}</li>
          <li parentName="ul">{`Generate Swift code for the schema types in a directory with the `}<inlineCode parentName="li">{`schema-name`}</inlineCode>{` provided.`}</li>
          <li parentName="ul">{`Generate Swift code for the operation and fragment models in a subfolder within the schema types output location.`}</li>
        </ul>
      </ExpansionPanelListItem>
      <ExpansionPanelListItem mdxType="ExpansionPanelListItem">
        <h4 {...{
          "id": "run-code-generation"
        }}><a parentName="h4" {...{
            "href": "#run-code-generation"
          }}>{`Run code generation`}</a></h4>
        <p>{`From your project's root directory, run:`}</p>
        <CodeBlock code={props.cliPath + " generate"} language="bash" showLineNumbers='true' mdxType="CodeBlock" />
        <p>{`The code generation engine creates your files with the extension `}<inlineCode parentName="p">{`.graphql.swift`}</inlineCode>{`.`}</p>
      </ExpansionPanelListItem>
      <ExpansionPanelListItem mdxType="ExpansionPanelListItem">
        <h4 {...{
          "id": "add-the-generated-schema-and-operation-files-to-your-target"
        }}><a parentName="h4" {...{
            "href": "#add-the-generated-schema-and-operation-files-to-your-target"
          }}>{`Add the generated schema and operation files to your target`}</a></h4>
        <p>{`By default, a directory containing your generated schema files is within a directory with the schema name you provided (i.e., `}<inlineCode parentName="p">{`MySchemaName`}</inlineCode>{`). Your generated operation and fragment files are in a subfolder within the same directory.`}</p>
        <p>{`If you created your target in an Xcode project or workspace, you'll need to manually add the generated files to your target.`}</p>
        <blockquote>
          <p parentName="blockquote"><strong parentName="p">{`Note:`}</strong>{` Because adding generated files to your Xcode targets must be done manually each time you generate new files, we highly recommend defining your project targets with SPM. Alternatively, you can generate your operations into the package that includes your schema files. For more information see the documentation for `}<a parentName="p" {...{
              "href": "/docs/ios/code-generation/codegen-configuration"
            }}>{`Code Generation Configuration`}</a>{`.`}</p>
        </blockquote>
      </ExpansionPanelListItem>
    </ExpansionPanelList>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      