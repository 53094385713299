import QueryOptions2 from "../../../gatsby-source-git/apollographql/apollo-client/react/v2/docs/shared/query-options.mdx";
import QueryResult2 from "../../../gatsby-source-git/apollographql/apollo-client/react/v2/docs/shared/query-result.mdx";
import MutationOptions2 from "../../../gatsby-source-git/apollographql/apollo-client/react/v2/docs/shared/mutation-options.mdx";
import MutationResult2 from "../../../gatsby-source-git/apollographql/apollo-client/react/v2/docs/shared/mutation-result.mdx";
import SubscriptionOptions2 from "../../../gatsby-source-git/apollographql/apollo-client/react/v2/docs/shared/subscription-options.mdx";
import SubscriptionResult2 from "../../../gatsby-source-git/apollographql/apollo-client/react/v2/docs/shared/subscription-result.mdx";
import ApolloProvider2 from "../../../gatsby-source-git/apollographql/apollo-client/react/v2/docs/shared/apollo-provider.mdx";
import * as React from 'react';
export default {
  QueryOptions2,
  QueryResult2,
  MutationOptions2,
  MutationResult2,
  SubscriptionOptions2,
  SubscriptionResult2,
  ApolloProvider2,
  React
};