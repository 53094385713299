import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpansionPanel = makeShortcode("ExpansionPanel");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ExpansionPanel title="CocoaPods Run Script" mdxType="ExpansionPanel">
      <p>{`Our CocoaPods install includes the code-generation scripts and binaries of the `}<inlineCode parentName="p">{`apollo`}</inlineCode>{` CLI client as files which will not be added to the framework, but which you can still call from a Run Script Build Phase. Add the following to the Run Script:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`# Don't run this during index builds
if [ $ACTION = "indexbuild" ]; then exit 0; fi

SCRIPT_PATH="\${PODS_ROOT}/Apollo/scripts"
cd "\${SRCROOT}/\${TARGET_NAME}"
"\${SCRIPT_PATH}"/run-bundled-codegen.sh codegen:generate --target=swift --includes=./**/*.graphql --localSchemaFile="schema.json" API.swift
`}</code></pre>
    </ExpansionPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      