import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table className="field-table api-ref">
      <thead>
  <tr>
    <td>Name /<br />Type</td>
    <td>Description</td>
  </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan="2">
            <p><strong parentName="p">{`Operation options`}</strong></p>
          </td>
        </tr>
        <tr>
          <td>
            <h6 {...{
              "id": "variables"
            }}><a parentName="h6" {...{
                "href": "#variables"
              }}><inlineCode parentName="a">{`variables`}</inlineCode></a></h6>
            <p><inlineCode parentName="p">{`{ [key: string]: any }`}</inlineCode></p>
          </td>
          <td>
            <p>{`An object containing all of the GraphQL variables your query requires to execute.`}</p>
            <p>{`Each key in the object corresponds to a variable name, and that key's value corresponds to the variable value.`}</p>
          </td>
        </tr>
        <tr>
          <td>
            <h6 {...{
              "id": "errorpolicy"
            }}><a parentName="h6" {...{
                "href": "#errorpolicy"
              }}><inlineCode parentName="a">{`errorPolicy`}</inlineCode></a></h6>
            <p><inlineCode parentName="p">{`ErrorPolicy`}</inlineCode></p>
          </td>
          <td>
            <p>{`Specifies how the query handles a response that returns both GraphQL errors and partial results.`}</p>
            <p>{`For details, see `}<a parentName="p" {...{
                "href": "/docs/react/data/error-handling/#graphql-error-policies"
              }}>{`GraphQL error policies`}</a>{`.`}</p>
            <p>{`The default value is `}<inlineCode parentName="p">{`none`}</inlineCode>{`, which causes the `}<inlineCode parentName="p">{`useReadQuery`}</inlineCode>{` hook to throw the error.`}</p>
          </td>
        </tr>
        <tr>
          <td colSpan="2">
            <p><strong parentName="p">{`Networking options`}</strong></p>
          </td>
        </tr>
        <tr>
          <td>
            <h6 {...{
              "id": "context"
            }}><a parentName="h6" {...{
                "href": "#context"
              }}><inlineCode parentName="a">{`context`}</inlineCode></a></h6>
            <p><inlineCode parentName="p">{`Record<string, any>`}</inlineCode></p>
          </td>
          <td>
            <p>{`If you're using `}<a parentName="p" {...{
                "href": "/docs/react/api/link/introduction/"
              }}>{`Apollo Link`}</a>{`, this object is the initial value of the `}<inlineCode parentName="p">{`context`}</inlineCode>{` object that's passed along your link chain.`}</p>
          </td>
        </tr>
        <tr>
          <td>
            <h6 {...{
              "id": "canonizeresults"
            }}><a parentName="h6" {...{
                "href": "#canonizeresults"
              }}><inlineCode parentName="a">{`canonizeResults`}</inlineCode></a></h6>
            <p><inlineCode parentName="p">{`Boolean`}</inlineCode></p>
          </td>
          <td>
            <blockquote>
              <p parentName="blockquote"><strong parentName="p"><undefined parentName="strong"><span {...{
                      "role": "img",
                      "aria-label": "warning"
                    }}>{`⚠️`}</span>{` Deprecated`}</undefined></strong>{`:
Using `}<inlineCode parentName="p">{`canonizeResults`}</inlineCode>{` can result in memory leaks so we generally do not recommend using this option anymore.
A future version of Apollo Client will contain a similar feature without the risk of memory leaks.`}</p>
            </blockquote>
            <p>{`If `}<inlineCode parentName="p">{`true`}</inlineCode>{`, result objects read from the cache will be `}<em parentName="p">{`canonized`}</em>{`, which means deeply-equal objects will also be `}<inlineCode parentName="p">{`===`}</inlineCode>{` (literally the same object), allowing much more efficient comparison of past/present results.`}</p>
            <p>{`The default value is `}<inlineCode parentName="p">{`false`}</inlineCode>{`.`}</p>
          </td>
        </tr>
        <tr>
          <td>
            <h6 {...{
              "id": "client"
            }}><a parentName="h6" {...{
                "href": "#client"
              }}><inlineCode parentName="a">{`client`}</inlineCode></a></h6>
            <p><inlineCode parentName="p">{`ApolloClient`}</inlineCode></p>
          </td>
          <td>
            <p>{`The instance of `}<inlineCode parentName="p">{`ApolloClient`}</inlineCode>{` to use to execute the query.`}</p>
            <p>{`By default, the instance that's passed down via context is used, but you can provide a different instance here.`}</p>
          </td>
        </tr>
        <tr>
          <td colSpan="2">
            <p><strong parentName="p">{`Caching options`}</strong></p>
          </td>
        </tr>
        <tr>
          <td>
            <h6 {...{
              "id": "fetchpolicy"
            }}><a parentName="h6" {...{
                "href": "#fetchpolicy"
              }}><inlineCode parentName="a">{`fetchPolicy`}</inlineCode></a></h6>
            <p><inlineCode parentName="p">{`SuspenseQueryHookFetchPolicy`}</inlineCode></p>
          </td>
          <td>
            <p>{`Specifies how the query interacts with the Apollo Client cache during execution (for example, whether it checks the cache for results before sending a request to the server).`}</p>
            <p>{`For details, see `}<a parentName="p" {...{
                "href": "/docs/react/data/queries/#setting-a-fetch-policy"
              }}>{`Setting a fetch
policy`}</a>{`. This hook only supports
the `}<inlineCode parentName="p">{`cache-first`}</inlineCode>{`, `}<inlineCode parentName="p">{`network-only`}</inlineCode>{`, `}<inlineCode parentName="p">{`no-cache`}</inlineCode>{`, and `}<inlineCode parentName="p">{`cache-and-network`}</inlineCode>{` fetch
policies.`}</p>
            <p>{`The default value is `}<inlineCode parentName="p">{`cache-first`}</inlineCode>{`.`}</p>
          </td>
        </tr>
        <tr>
          <td>
            <h6 {...{
              "id": "returnpartialdata"
            }}><a parentName="h6" {...{
                "href": "#returnpartialdata"
              }}><inlineCode parentName="a">{`returnPartialData`}</inlineCode></a></h6>
            <p><inlineCode parentName="p">{`boolean`}</inlineCode></p>
          </td>
          <td>
            <p>{`If `}<inlineCode parentName="p">{`true`}</inlineCode>{`, the query can return `}<em parentName="p">{`partial`}</em>{` results from the cache if the cache doesn't contain results for `}<em parentName="p">{`all`}</em>{` queried fields.`}</p>
            <p>{`The default value is `}<inlineCode parentName="p">{`false`}</inlineCode>{`.`}</p>
          </td>
        </tr>
        <tr>
          <td>
            <h6 {...{
              "id": "refetchwritepolicy"
            }}><a parentName="h6" {...{
                "href": "#refetchwritepolicy"
              }}><inlineCode parentName="a">{`refetchWritePolicy`}</inlineCode></a></h6>
            <p><inlineCode parentName="p">{`"merge" | "overwrite"`}</inlineCode></p>
          </td>
          <td>
            <p>{`Watched queries must opt into overwriting existing data on refetch, by passing `}<inlineCode parentName="p">{`refetchWritePolicy: "overwrite"`}</inlineCode>{` in their `}<inlineCode parentName="p">{`WatchQueryOptions`}</inlineCode>{`.`}</p>
            <p>{`The default value is `}<inlineCode parentName="p">{`"overwrite"`}</inlineCode>{`.`}</p>
          </td>
        </tr>
        <tr>
          <td>
            <h6 {...{
              "id": "skip-deprecated"
            }}><a parentName="h6" {...{
                "href": "#skip-deprecated"
              }}><inlineCode parentName="a">{`skip`}</inlineCode>{` (deprecated)`}</a></h6>
            <p><inlineCode parentName="p">{`boolean`}</inlineCode></p>
          </td>
          <td>
            <p>{`If `}<inlineCode parentName="p">{`true`}</inlineCode>{`, the query is not executed. The default value is `}<inlineCode parentName="p">{`false`}</inlineCode>{`.`}</p>
            <p>{`This option is deprecated and only supported to ease the migration from `}<inlineCode parentName="p">{`useQuery`}</inlineCode>{`. It will be removed in a future release.
Please use `}<a parentName="p" {...{
                "href": "/docs/react/api/react/hooks#skiptoken%60"
              }}><inlineCode parentName="a">{`skipToken`}</inlineCode></a>{` instead of the `}<inlineCode parentName="p">{`skip`}</inlineCode>{` option as it is more type-safe.`}</p>
          </td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      