import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpansionPanel = makeShortcode("ExpansionPanel");
const ExpansionPanelList = makeShortcode("ExpansionPanelList");
const ExpansionPanelListItem = makeShortcode("ExpansionPanelListItem");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ExpansionPanel title="SPM with Package.swift" mdxType="ExpansionPanel">
      <p>{`If your project uses its own `}<inlineCode parentName="p">{`Package.swift`}</inlineCode>{` file, you can add Apollo iOS as a dependency there.`}</p>
      <ExpansionPanelList mdxType="ExpansionPanelList">
        <ExpansionPanelListItem mdxType="ExpansionPanelListItem">
          <h4 {...{
            "id": "add-apollo-ios-to-your-dependencies-list"
          }}><a parentName="h4" {...{
              "href": "#add-apollo-ios-to-your-dependencies-list"
            }}>{`Add Apollo iOS to your dependencies list`}</a></h4>
          <pre><code parentName="pre" {...{
              "className": "language-swift",
              "metastring": "title=\"Package.swift\"",
              "title": "\"Package.swift\""
            }}>{`dependencies: [
    .package(
        url: "https://github.com/apollographql/apollo-ios.git",
        .upToNextMajor(from: "1.0.0")
    ),
],
`}</code></pre>
        </ExpansionPanelListItem>
        <ExpansionPanelListItem mdxType="ExpansionPanelListItem">
          <h4 {...{
            "id": "link-the-apollo-product-to-your-package-target"
          }}><a parentName="h4" {...{
              "href": "#link-the-apollo-product-to-your-package-target"
            }}>{`Link the Apollo product to your package target`}</a></h4>
          <p>{`Any targets in your application that will use `}<inlineCode parentName="p">{`ApolloClient`}</inlineCode>{` need to have a dependency on the `}<inlineCode parentName="p">{`Apollo`}</inlineCode>{` product.`}</p>
          <pre><code parentName="pre" {...{
              "className": "language-swift",
              "metastring": "title=\"Package.swift\"",
              "title": "\"Package.swift\""
            }}>{`.target(
    name: "MyApp",
    dependencies: [
        .product(name: "Apollo", package: "apollo-ios"),
    ]
)
`}</code></pre>
          <blockquote>
            <p parentName="blockquote"><strong parentName="p">{`Note:`}</strong>{` Targets that only use Apollo's generated models don't need to be linked to the `}<inlineCode parentName="p">{`Apollo`}</inlineCode>{` product.`}</p>
          </blockquote>
        </ExpansionPanelListItem>
      </ExpansionPanelList>
    </ExpansionPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      