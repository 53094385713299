import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpansionPanel = makeShortcode("ExpansionPanel");
const ExpansionPanelList = makeShortcode("ExpansionPanelList");
const ExpansionPanelListItem = makeShortcode("ExpansionPanelListItem");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ExpansionPanel title="Swift Package Manager Installation" mdxType="ExpansionPanel">
      <blockquote>
        <p parentName="blockquote"><strong parentName="p">{`Note:`}</strong>{` These instructions use the Xcode 13 UI. Xcode 11 is the first version of Xcode that integrates Swift Package manager, whereas older versions require using the command line. If you're using an older version of Xcode, we recommend using CocoaPods instead.`}</p>
      </blockquote>
      <ExpansionPanelList mdxType="ExpansionPanelList">
        <ExpansionPanelListItem mdxType="ExpansionPanelListItem">
          <p>{`Go to `}<strong parentName="p">{`File > Add Packages...`}</strong></p>
          <img className="screenshot" src="/docs/6639c996a1eb9da8a267483a7cf7e31e/spm_packages_add_package.jpg" alt="Adding an SPM package" width="300" />
        </ExpansionPanelListItem>
        <ExpansionPanelListItem mdxType="ExpansionPanelListItem">
          <p>{`In the dialog that appears, paste the URL of the Apollo iOS GitHub repo (`}<inlineCode parentName="p">{`https://github.com/apollographql/apollo-ios.git`}</inlineCode>{`) into the search bar, then select the `}<inlineCode parentName="p">{`apollo-ios`}</inlineCode>{` package that appears:`}</p>
          <img className="screenshot" src="/docs/f0f4d748e5a7b22a1f5d9329dc7d7984/spm_packages_dialog.jpg" alt="Pasting the Apollo iOS GitHub URL" />
        </ExpansionPanelListItem>
        <ExpansionPanelListItem mdxType="ExpansionPanelListItem">
          <p>{`Select which version you want to use (`}<a parentName="p" {...{
              "href": "https://github.com/apollographql/apollo-ios/releases"
            }}>{`see version history`}</a>{`), then click `}<strong parentName="p">{`Add Package`}</strong>{`. Note that Xcode might not automatically select the latest version number!`}</p>
          <blockquote>
            <p parentName="blockquote">{`Xcode automatically suggests the dependency rule `}<inlineCode parentName="p">{`Up to Next Major`}</inlineCode>{`. We `}<strong parentName="p">{`strongly`}</strong>{` suggest that until the release of Apollo iOS `}<inlineCode parentName="p">{`1.x`}</inlineCode>{`, you select `}<inlineCode parentName="p">{`Up To Next Minor`}</inlineCode>{` instead, because we might release breaking changes in a minor version.`}</p>
          </blockquote>
        </ExpansionPanelListItem>
        <ExpansionPanelListItem mdxType="ExpansionPanelListItem">
          <p>{`Select which packages you want to use. If you're getting started, we recommend selecting just the main `}<inlineCode parentName="p">{`Apollo`}</inlineCode>{` library for now. You can always add other packages later if you need them.`}</p>
          <img className="screenshot" src="/docs/8169b8c9b206d3359d5ed62b22b37c3c/spm_select_package.jpg" alt="Selecting Apollo iOS packages" />
          <blockquote>
            <p parentName="blockquote"><strong parentName="p">{`Note:`}</strong>{` Do `}<strong parentName="p">{`not`}</strong>{` select the `}<inlineCode parentName="p">{`Apollo-Dynamic`}</inlineCode>{` target. This target is only for  projects that link to Apollo iOS. Most projects do not need to do this.`}</p>
          </blockquote>
          <p>{`Then, click `}<strong parentName="p">{`Add Package`}</strong>{`.`}</p>
        </ExpansionPanelListItem>
        <ExpansionPanelListItem mdxType="ExpansionPanelListItem">
          <p>{`You're done!`}</p>
        </ExpansionPanelListItem>
      </ExpansionPanelList>
    </ExpansionPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      