import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`scheduled_delay`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5s`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The delay in seconds from receiving the first span to sending the batch.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`max_concurrent_exports`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The maximum number of overlapping export requests.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`max_export_batch_size`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`512`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of spans to include in a batch. May be limited by maximum message size limits.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`max_export_timeout`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`30s`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The timeout in seconds for sending spans before dropping the data.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`max_queue_size`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2048`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The maximum number of spans to be buffered before dropping span data.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      