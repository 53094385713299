import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpansionPanel = makeShortcode("ExpansionPanel");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ExpansionPanel title="Adding SQLite with CocoaPods" mdxType="ExpansionPanel">
      <p>{`Add the following to your `}<inlineCode parentName="p">{`Podfile`}</inlineCode>{`:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`pod 'Apollo'
pod 'Apollo/SQLite'
`}</code></pre>
      <p>{`Note that if you're specifying a version for `}<inlineCode parentName="p">{`Apollo`}</inlineCode>{`, you need to specify the same version for `}<inlineCode parentName="p">{`Apollo/SQLite`}</inlineCode>{`. `}</p>
    </ExpansionPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      