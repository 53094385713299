import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Option`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`mutation`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DocumentNode`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A GraphQL mutation document parsed into an AST by `}<inlineCode parentName="td">{`graphql-tag`}</inlineCode>{`. `}<strong parentName="td">{`Optional`}</strong>{` for the `}<inlineCode parentName="td">{`useMutation`}</inlineCode>{` Hook since the mutation can be passed in as the first parameter to the Hook. `}<strong parentName="td">{`Required`}</strong>{` for the `}<inlineCode parentName="td">{`Mutation`}</inlineCode>{` component.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`variables`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`{ `}{`[key: string]`}{`: any }`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An object containing all of the variables your mutation needs to execute`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`update`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(cache: DataProxy, mutationResult: FetchResult)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A function used to update the cache after a mutation occurs`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ignoreResults`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If true, the returned `}<inlineCode parentName="td">{`data`}</inlineCode>{` property will not update with the mutation result.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`optimisticResponse`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Provide a `}<a parentName="td" {...{
              "href": "/docs/react/performance/optimistic-ui/"
            }}>{`mutation response`}</a>{` before the result comes back from the server`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`refetchQueries`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array`}{`<`}{`string`}{`|`}{`{ query: DocumentNode, variables?: TVariables}`}{`>`}{` `}{`|`}{` ((mutationResult: FetchResult) => Array`}{`<`}{`string`}{`|`}{`{ query: DocumentNode, variables?: TVariables}`}{`>`}{`)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An array or function that allows you to specify which queries you want to refetch after a mutation has occurred. Array values can either be queries (with optional variables) or just the string names of queries to be refeteched.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`awaitRefetchQueries`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Queries refetched as part of `}<inlineCode parentName="td">{`refetchQueries`}</inlineCode>{` are handled asynchronously, and are not waited on before the mutation is completed (resolved). Setting this to `}<inlineCode parentName="td">{`true`}</inlineCode>{` will make sure refetched queries are completed before the mutation is considered done. `}<inlineCode parentName="td">{`false`}</inlineCode>{` by default.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onCompleted`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(data: TData) => void`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A callback executed once your mutation successfully completes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onError`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(error: ApolloError) => void`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A callback executed in the event of an error.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`context`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Record`}{`<`}{`string, any`}{`>`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Shared context between your component and your network interface (Apollo Link). Useful for setting headers from props or sending information to the `}<inlineCode parentName="td">{`request`}</inlineCode>{` function of Apollo Boost.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`client`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ApolloClient`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An `}<inlineCode parentName="td">{`ApolloClient`}</inlineCode>{` instance. By default `}<inlineCode parentName="td">{`useMutation`}</inlineCode>{` / `}<inlineCode parentName="td">{`Mutation`}</inlineCode>{` uses the client passed down via context, but a different client can be passed in.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      