import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpansionPanel = makeShortcode("ExpansionPanel");
const ExpansionPanelList = makeShortcode("ExpansionPanelList");
const ExpansionPanelListItem = makeShortcode("ExpansionPanelListItem");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ExpansionPanel title="SPM with Xcode Project" mdxType="ExpansionPanel">
      <p>{`If using Swift Package Manager within an Xcode project or workspace, use the Xcode project configuration UI to add the Apollo iOS package.`}</p>
      <ExpansionPanelList mdxType="ExpansionPanelList">
        <ExpansionPanelListItem mdxType="ExpansionPanelListItem">
          <p>{`Go to `}<strong parentName="p">{`File > Add Packages...`}</strong></p>
          <img className="screenshot" src="/docs/f1df58fd0163d53db916f9b95b3d386f/spm_packages_add_package.png" alt="Adding an SPM package" width="560" />
        </ExpansionPanelListItem>
        <ExpansionPanelListItem mdxType="ExpansionPanelListItem">
          <p>{`In the dialog that appears, paste the URL of the Apollo iOS GitHub repo (`}<inlineCode parentName="p">{`https://github.com/apollographql/apollo-ios.git`}</inlineCode>{`) into the search bar, then select the `}<inlineCode parentName="p">{`apollo-ios`}</inlineCode>{` package that appears:`}</p>
          <img className="screenshot" src="/docs/8f61d5c1a67e539e4d6ed7207d2c6959/spm_packages_dialog.png" alt="Pasting the Apollo iOS GitHub URL" width="1035" />
        </ExpansionPanelListItem>
        <ExpansionPanelListItem mdxType="ExpansionPanelListItem">
          <p>{`Select which version you want to use (`}<a parentName="p" {...{
              "href": "https://github.com/apollographql/apollo-ios/releases"
            }}>{`see version history`}</a>{`), then click `}<strong parentName="p">{`Add Package`}</strong>{`.`}</p>
          <blockquote>
            <p parentName="blockquote"><strong parentName="p">{`Note:`}</strong>{` Xcode might not automatically select the latest version number, please check.`}</p>
          </blockquote>
        </ExpansionPanelListItem>
        <ExpansionPanelListItem mdxType="ExpansionPanelListItem">
          <p>{`Select which packages you want to use. If you're getting started, we recommend selecting just the main `}<inlineCode parentName="p">{`Apollo`}</inlineCode>{` library for now. You can always add other packages later if you need them.`}</p>
          <img className="screenshot" src="/docs/65a681c7a5d686cce2265b552f1ae129/spm_select_package.png" alt="Selecting Apollo iOS packages" width="1035" />
          <blockquote>
            <p parentName="blockquote"><strong parentName="p">{`Notes:`}</strong>{` `}</p>
            <ol parentName="blockquote">
              <li parentName="ol">{`Do not select the `}<inlineCode parentName="li">{`Apollo-Dynamic`}</inlineCode>{` product unless your project is configured to use dynamic linking of the Apollo iOS framework. Most projects do not need to link to this product.`}</li>
              <li parentName="ol"><strong parentName="li">{`Do not select the `}<inlineCode parentName="strong">{`apollo-ios-cli`}</inlineCode>{` package.`}</strong>{` This product is the CLI executable for code generation. If you link it to your project as a dependency it `}<strong parentName="li">{`will`}</strong>{` cause build errors.`}</li>
            </ol>
          </blockquote>
          <p>{`Then, click `}<strong parentName="p">{`Add Package`}</strong>{`.`}</p>
        </ExpansionPanelListItem>
        <ExpansionPanelListItem mdxType="ExpansionPanelListItem">
          <p>{`You're done!`}</p>
        </ExpansionPanelListItem>
      </ExpansionPanelList>
    </ExpansionPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      