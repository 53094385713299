import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import SPMInstallCLI from "../cli-install/spm.mdx";
import SPMXcodeInstallCLI from "../cli-install/spm-xcode.mdx";
import PodsInstallCLI from "../cli-install/pods.mdx";
import SetupCodegenPanel from "./single-panel.mdx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpansionPanel = makeShortcode("ExpansionPanel");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <a name="spm-setup-codegen" />
    <ExpansionPanel title="SPM with Package.swift" mdxType="ExpansionPanel">
      <SetupCodegenPanel cliPath="./apollo-ios-cli" moduleTypeOption="`.swiftPackageManager`" installComponent={<SPMInstallCLI mdxType="SPMInstallCLI" />} mdxType="SetupCodegenPanel" />
    </ExpansionPanel>
    <a name="spm-xcode-setup-codegen" />
    <ExpansionPanel title="SPM with Xcode Project" mdxType="ExpansionPanel">
      <SetupCodegenPanel cliPath="./apollo-ios-cli" moduleTypeOption="`.swiftPackageManager`" installComponent={<SPMXcodeInstallCLI mdxType="SPMXcodeInstallCLI" />} mdxType="SetupCodegenPanel" />
    </ExpansionPanel>
    <a name="pods-setup-codegen" />
    <ExpansionPanel title="Cocoapods" mdxType="ExpansionPanel">
      <SetupCodegenPanel cliPath="./Pods/Apollo/apollo-ios-cli" moduleTypeOption="`.other`" installComponent={<PodsInstallCLI mdxType="PodsInstallCLI" />} mdxType="SetupCodegenPanel" />
    </ExpansionPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      