import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpansionPanel = makeShortcode("ExpansionPanel");
const ExpansionPanelList = makeShortcode("ExpansionPanelList");
const ExpansionPanelListItem = makeShortcode("ExpansionPanelListItem");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ExpansionPanel title="CocoaPods Installation" mdxType="ExpansionPanel">
      <ExpansionPanelList mdxType="ExpansionPanelList">
        <ExpansionPanelListItem mdxType="ExpansionPanelListItem">
          <h4 {...{
            "id": "install-or-update-cocoapods"
          }}><a parentName="h4" {...{
              "href": "#install-or-update-cocoapods"
            }}>{`Install or update CocoaPods`}</a></h4>
          <p>{`Because Apollo iOS uses Swift 5, you need to use CocoaPods version `}<inlineCode parentName="p">{`1.7.0`}</inlineCode>{` or later. You can install CocoaPods with the following command:`}</p>
          <pre><code parentName="pre" {...{
              "className": "language-sh"
            }}>{`gem install cocoapods
`}</code></pre>
        </ExpansionPanelListItem>
        <ExpansionPanelListItem mdxType="ExpansionPanelListItem">
          <h4 {...{
            "id": "add-dependencies"
          }}><a parentName="h4" {...{
              "href": "#add-dependencies"
            }}>{`Add dependencies`}</a></h4>
          <p>{`Add `}<inlineCode parentName="p">{`pod "Apollo"`}</inlineCode>{` to your Podfile.`}</p>
          <ul>
            <li parentName="ul">{`To include the `}<inlineCode parentName="li">{`ApolloSQLite`}</inlineCode>{` framework, also add `}<inlineCode parentName="li">{`pod "Apollo/SQLite"`}</inlineCode></li>
            <li parentName="ul">{`To include the `}<inlineCode parentName="li">{`ApolloWebSocket`}</inlineCode>{` framework, also add `}<inlineCode parentName="li">{`pod "Apollo/WebSocket"`}</inlineCode></li>
          </ul>
        </ExpansionPanelListItem>
        <ExpansionPanelListItem mdxType="ExpansionPanelListItem">
          <p>{`Run `}<inlineCode parentName="p">{`pod install`}</inlineCode>{`.`}</p>
        </ExpansionPanelListItem>
        <ExpansionPanelListItem mdxType="ExpansionPanelListItem">
          <p>{`Use the `}<inlineCode parentName="p">{`.xcworkspace`}</inlineCode>{` file generated by CocoaPods to work on your project.`}</p>
        </ExpansionPanelListItem>
        <ExpansionPanelListItem mdxType="ExpansionPanelListItem">
          <p>{`You're done!`}</p>
        </ExpansionPanelListItem>
      </ExpansionPanelList>
    </ExpansionPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      