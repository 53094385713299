import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The Apollo iOS SPM package includes the Codegen CLI as an executable target. This ensures you always have a valid CLI version for your Apollo iOS version.`}</p>
    <p>{`To simplify accessing the Codegen CLI, you can run the included `}<inlineCode parentName="p">{`InstallCLI`}</inlineCode>{` SPM plugin.`}</p>
    <p>{`This plugin builds the CLI and creates a symbolic link to the executable in your project root.`}</p>
    <p>{`If you use Swift packages through Xcode, you can right-click on your project in the Xcode file explorer, revealing an `}<strong parentName="p">{`Install CLI`}</strong>{` plugin command. Selecting this command presents a dialog allowing you to grant the plugin "write" access to your project directory.`}</p>
    <img className="screenshot" src="/docs/141df0a63f768d56e3a7e9100bb32cb1/apollo-xcode-plugin.png" alt="Where to find the SPM plugin commands in Xcode" />
    <p>{`After the plugin installs, it creates a symbolic link to the Codegen CLI (named `}<inlineCode parentName="p">{`apollo-ios-cli`}</inlineCode>{`) in your project root folder. You can now run the CLI from the command line with `}<inlineCode parentName="p">{`./apollo-ios-cli`}</inlineCode>{`.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note:`}</strong>{` Because the `}<inlineCode parentName="p">{`apollo-ios-cli`}</inlineCode>{` in your project root is only a symbolic link, it only works if the compiled CLI executable exists. This is generally located in your Xcode Derived Data or the `}<inlineCode parentName="p">{`.build`}</inlineCode>{` folder. If these are cleared, you can rerun the Install CLI plugin to rebuild the CLI executable.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      