import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Option`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`query`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DocumentNode`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A GraphQL query document parsed into an AST by `}<inlineCode parentName="td">{`graphql-tag`}</inlineCode>{`. `}<strong parentName="td">{`Optional`}</strong>{` for the `}<inlineCode parentName="td">{`useQuery`}</inlineCode>{` Hook since the query can be passed in as the first parameter to the Hook. `}<strong parentName="td">{`Required`}</strong>{` for the `}<inlineCode parentName="td">{`Query`}</inlineCode>{` component.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`variables`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`{ `}{`[key: string]`}{`: any }`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An object containing all of the variables your query needs to execute`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pollInterval`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specifies the interval in ms at which you want your component to poll for data. Defaults to 0 (no polling).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`notifyOnNetworkStatusChange`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Whether updates to the network status or network error should re-render your component. Defaults to false.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`fetchPolicy`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FetchPolicy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`How you want your component to interact with the Apollo cache. Defaults to "cache-first".`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`errorPolicy`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ErrorPolicy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`How you want your component to handle network and GraphQL errors. Defaults to "none", which means we treat GraphQL errors as runtime errors.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ssr`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pass in false to skip your query during server-side rendering.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`displayName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The name of your component to be displayed in React DevTools. Defaults to 'Query'.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`skip`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If skip is true, the query will be skipped entirely. `}<strong parentName="td">{`Not available with `}<inlineCode parentName="strong">{`useLazyQuery`}</inlineCode></strong>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onCompleted`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(data: TData `}{`|`}{` {}) => void`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A callback executed once your query successfully completes.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onError`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(error: ApolloError) => void`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A callback executed in the event of an error.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`context`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Record`}{`<`}{`string, any`}{`>`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Shared context between your component and your network interface (Apollo Link). Useful for setting headers from props or sending information to the `}<inlineCode parentName="td">{`request`}</inlineCode>{` function of Apollo Boost.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`partialRefetch`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If `}<inlineCode parentName="td">{`true`}</inlineCode>{`, perform a query `}<inlineCode parentName="td">{`refetch`}</inlineCode>{` if the query result is marked as being partial, and the returned data is reset to an empty Object by the Apollo Client `}<inlineCode parentName="td">{`QueryManager`}</inlineCode>{` (due to a cache miss). The default value is `}<inlineCode parentName="td">{`false`}</inlineCode>{` for backwards-compatibility's sake, but should be changed to true for most use-cases.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`client`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ApolloClient`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An `}<inlineCode parentName="td">{`ApolloClient`}</inlineCode>{` instance. By default `}<inlineCode parentName="td">{`useQuery`}</inlineCode>{` / `}<inlineCode parentName="td">{`Query`}</inlineCode>{` uses the client passed down via context, but a different client can be passed in.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`returnPartialData`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Opt into receiving partial results from the cache for queries that are not fully satisfied by the cache. false by default.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      