import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpansionPanel = makeShortcode("ExpansionPanel");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ExpansionPanel title="How to obtain a graph API key" mdxType="ExpansionPanel">
      <blockquote>
        <p parentName="blockquote"><strong parentName="p">{`API keys are secret credentials.`}</strong>{` Never share them outside your organization or commit them to version control. Delete and replace API keys that you believe are compromised.`}</p>
        <p parentName="blockquote">{`API keys are not available for `}<a parentName="p" {...{
            "href": "https://www.apollographql.com/docs/studio/dev-graphs/"
          }}>{`development graphs`}</a>{`.`}</p>
      </blockquote>
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Go to `}<a parentName="p" {...{
              "href": "https://studio.apollographql.com/"
            }}>{`studio.apollographql.com`}</a>{` and click the graph you want to obtain an API key for.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li"><strong parentName="p">{`If a "Publish your Schema" dialog appears`}</strong>{`, select the `}<strong parentName="p">{`From Apollo Server`}</strong>{` tab. Copy the value that appears after `}<inlineCode parentName="p">{`APOLLO_KEY=`}</inlineCode>{` in the instructions (it begins with `}<inlineCode parentName="p">{`service:`}</inlineCode>{`), and you're all set.`}</p>
          <p parentName="li"><strong parentName="p">{`Otherwise`}</strong>{`, proceed to the next step.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Open your graph's Settings page and scroll down to the API Keys section. Either copy an existing key or click `}<strong parentName="p">{`Create New Key`}</strong>{`. Give your key a name, such as `}<inlineCode parentName="p">{`Production`}</inlineCode>{`. This helps you keep track of each API key's use.`}</p>
          <blockquote parentName="li">
            <p parentName="blockquote">{`If you don't see the API Keys section, you don't have sufficient permissions for your graph. Only organization members with the `}<inlineCode parentName="p">{`Org Admin`}</inlineCode>{` or `}<inlineCode parentName="p">{`Graph Admin`}</inlineCode>{` role can manage graph API keys. `}<a parentName="p" {...{
                "href": "https://www.apollographql.com/docs/studio/org/members/"
              }}>{`Learn more about member roles.`}</a></p>
          </blockquote>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Copy the key's value.`}</p>
        </li>
      </ol>
    </ExpansionPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      