import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`If you use Cocoapods, Apollo iOS compiles the Codegen CLI into an executable shell application during `}<inlineCode parentName="p">{`pod install`}</inlineCode>{` (located in `}<inlineCode parentName="p">{`Pods/Apollo/apollo-ios-cli`}</inlineCode>{`).`}</p>
    <p>{`After installing the Apollo iOS pod, you can run the Codegen CLI from the directory of your `}<inlineCode parentName="p">{`Podfile`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`./Pods/Apollo/apollo-ios-cli \${Command Name} -\${Command Arguments}
`}</code></pre>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note:`}</strong>{` If you are using `}<inlineCode parentName="p">{`:path`}</inlineCode>{` in your Podfile to link to a local copy of Apollo iOS, the CLI will not be automatically available. You will need to manually build the Codegen CLI. See the `}<a parentName="p" {...{
          "href": "/docs/ios/code-generation/codegen-cli#installation"
        }}>{`CLI installation guide`}</a>{` for directions on how to do that.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      