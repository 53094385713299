import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <blockquote>
      <h4 parentName="blockquote" {...{
        "id": "separating-cache-mutations-from-network-operations"
      }}><a parentName="h4" {...{
          "href": "#separating-cache-mutations-from-network-operations"
        }}>{`Separating cache mutations from network operations`}</a></h4>
      <p parentName="blockquote">{`By flagging a query as a `}<inlineCode parentName="p">{`LocalCacheMutation`}</inlineCode>{`, the generated model for that cache mutation no longer conforms to `}<inlineCode parentName="p">{`GraphQLQuery`}</inlineCode>{`. This means you can no longer use that cache mutation as a query operation.`}</p>
      <p parentName="blockquote">{`Fundamentally, this is because cache mutation models are `}<em parentName="p">{`mutable`}</em>{`, whereas network response data is `}<em parentName="p">{`immutable`}</em>{`. Cache mutations are designed to access and mutate only the data necessary. `}</p>
      <p parentName="blockquote">{`If our cache mutation models were mutable, mutating them outside of a `}<inlineCode parentName="p">{`ReadWriteTransaction`}</inlineCode>{` wouldn't persist any changes to the cache. Additionally, mutable data models require nearly double the generated code. By maintaining immutable models, we avoid this confusion and reduce our generated code.`}</p>
      <p parentName="blockquote">{`Avoid creating mutable versions of entire query operations. Instead, define mutable fragments or queries to mutate only the fields necessary.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      