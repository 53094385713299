import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Web Framework`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Integration Package`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://aws.amazon.com/lambda/"
            }}>{`AWS Lambda`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.npmjs.com/package/@as-integrations/aws-lambda"
            }}><inlineCode parentName="a">{`@as-integrations/aws-lambda`}</inlineCode></a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://azure.microsoft.com/en-us/services/functions/"
            }}>{`Azure Functions`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.npmjs.com/package/@as-integrations/azure-functions"
            }}><inlineCode parentName="a">{`@as-integrations/azure-functions`}</inlineCode></a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://workers.cloudflare.com/"
            }}>{`Cloudflare Workers`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.npmjs.com/package/@as-integrations/cloudflare-workers"
            }}><inlineCode parentName="a">{`@as-integrations/cloudflare-workers`}</inlineCode></a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://fastify.io/"
            }}>{`Fastify`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.npmjs.com/package/@as-integrations/fastify"
            }}><inlineCode parentName="a">{`@as-integrations/fastify`}</inlineCode></a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://hapi.dev/"
            }}>{`Hapi`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.npmjs.com/package/@as-integrations/hapi"
            }}><inlineCode parentName="a">{`@as-integrations/hapi`}</inlineCode></a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://koajs.com/"
            }}>{`Koa`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.npmjs.com/package/@as-integrations/koa"
            }}><inlineCode parentName="a">{`@as-integrations/koa`}</inlineCode></a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://nextjs.org"
            }}>{`Next.js`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.npmjs.com/package/@as-integrations/next"
            }}><inlineCode parentName="a">{`@as-integrations/next`}</inlineCode></a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://v3.nuxtjs.org/"
            }}>{`Nuxt`}</a>{` / `}<a parentName="td" {...{
              "href": "https://github.com/unjs/h3"
            }}>{`h3`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.npmjs.com/package/@as-integrations/h3"
            }}><inlineCode parentName="a">{`@as-integrations/h3`}</inlineCode></a></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      