import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`data`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TData`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An object containing the result of your GraphQL query. Defaults to `}<inlineCode parentName="td">{`undefined`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`loading`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A boolean that indicates whether the request is in flight`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`error`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ApolloError`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A runtime error with `}<inlineCode parentName="td">{`graphQLErrors`}</inlineCode>{` and `}<inlineCode parentName="td">{`networkError`}</inlineCode>{` properties`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`variables`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`{ `}{`[key: string]`}{`: any }`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An object containing the variables the query was called with`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`networkStatus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NetworkStatus`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A number from 1-8 corresponding to the detailed state of your network request. Includes information about refetching and polling status. Used in conjunction with the `}<inlineCode parentName="td">{`notifyOnNetworkStatusChange`}</inlineCode>{` prop.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`refetch`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(variables?: TVariables) => Promise`}{`<`}{`ApolloQueryResult`}{`>`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A function that allows you to refetch the query and optionally pass in new variables`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`fetchMore`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`({ query?: DocumentNode, variables?: TVariables, updateQuery: Function}) => Promise`}{`<`}{`ApolloQueryResult`}{`>`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A function that enables `}<a parentName="td" {...{
              "href": "/docs/react/data/pagination/"
            }}>{`pagination`}</a>{` for your query`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`startPolling`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(interval: number) => void`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This function sets up an interval in ms and fetches the query each time the specified interval passes.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`stopPolling`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`() => void`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This function stops the query from polling.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`subscribeToMore`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(options: { document: DocumentNode, variables?: TVariables, updateQuery?: Function, onError?: Function}) => () => void`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A function that sets up a `}<a parentName="td" {...{
              "href": "/docs/react/advanced/subscriptions/"
            }}>{`subscription`}</a>{`. `}<inlineCode parentName="td">{`subscribeToMore`}</inlineCode>{` returns a function that you can use to unsubscribe.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`updateQuery`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(previousResult: TData, options: { variables: TVariables }) => TData`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A function that allows you to update the query's result in the cache outside the context of a fetch, mutation, or subscription`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`client`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ApolloClient`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Your `}<inlineCode parentName="td">{`ApolloClient`}</inlineCode>{` instance. Useful for manually firing queries or writing data to the cache.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`called`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A boolean indicating if the query function has been called, used by `}<inlineCode parentName="td">{`useLazyQuery`}</inlineCode>{` (not set for `}<inlineCode parentName="td">{`useQuery`}</inlineCode>{` / `}<inlineCode parentName="td">{`Query`}</inlineCode>{`).`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      