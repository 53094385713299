import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MultiCodeBlock = makeShortcode("MultiCodeBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`In your app Gradle file, apply the `}<inlineCode parentName="p">{`com.apollographql.apollo`}</inlineCode>{` plugin:`}</p>
    <p>{`Using the `}<a parentName="p" {...{
        "href": "https://docs.gradle.org/current/userguide/plugins.html#sec:plugins_block"
      }}>{`plugins DSL`}</a>{`:`}</p>
    <MultiCodeBlock mdxType="MultiCodeBlock">
      <pre><code parentName="pre" {...{
          "className": "language-kotlin"
        }}>{`plugins {
  // ...
  id("com.apollographql.apollo").version("x.y.z")
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-groovy"
        }}>{`plugins {
  // ...
  id("com.apollographql.apollo").version("x.y.z")
}
`}</code></pre>
    </MultiCodeBlock>
    <p>{`Or using the `}<a parentName="p" {...{
        "href": "https://docs.gradle.org/current/userguide/plugins.html#sec:old_plugin_application"
      }}>{`legacy syntax`}</a>{`:`}</p>
    <MultiCodeBlock mdxType="MultiCodeBlock">
      <pre><code parentName="pre" {...{
          "className": "language-kotlin"
        }}>{`buildscript {
  // ...
  classpath("com.apollographql.apollo:apollo-gradle-plugin:x.y.z")
}

apply(plugin = "com.apollographql.apollo")
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-groovy"
        }}>{`buildscript {
  // ...
  classpath("com.apollographql.apollo:apollo-gradle-plugin:x.y.z")
}

apply plugin: "com.apollographql.apollo"
`}</code></pre>
    </MultiCodeBlock>
    <p>{`The plugin is hosted on the Gradle plugin portal, Jcenter and Maven Central.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      