import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Mutate function:`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`mutate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(options?: MutationOptions) => Promise`}{`<`}{`FetchResult`}{`>`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A function to trigger a mutation from your UI. You can optionally pass `}<inlineCode parentName="td">{`variables`}</inlineCode>{`, `}<inlineCode parentName="td">{`optimisticResponse`}</inlineCode>{`, `}<inlineCode parentName="td">{`refetchQueries`}</inlineCode>{`, and `}<inlineCode parentName="td">{`update`}</inlineCode>{` in as options, which will override options/props passed to `}<inlineCode parentName="td">{`useMutation`}</inlineCode>{` / `}<inlineCode parentName="td">{`Mutation`}</inlineCode>{`. The function returns a promise that fulfills with your mutation result.`}</td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`Mutation result:`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`data`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TData`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The data returned from your mutation. It can be undefined if `}<inlineCode parentName="td">{`ignoreResults`}</inlineCode>{` is true.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`loading`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A boolean indicating whether your mutation is in flight`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`error`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ApolloError`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Any errors returned from the mutation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`called`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A boolean indicating if the mutate function has been called`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`client`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ApolloClient`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Your `}<inlineCode parentName="td">{`ApolloClient`}</inlineCode>{` instance. Useful for invoking cache methods outside the context of the update function, such as `}<inlineCode parentName="td">{`client.writeData`}</inlineCode>{` and `}<inlineCode parentName="td">{`client.readQuery`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      