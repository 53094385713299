import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <blockquote>
      <p parentName="blockquote">{`In the examples below, we use top-level `}<a parentName="p" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/await#top_level_await"
        }}><inlineCode parentName="a">{`await`}</inlineCode></a>{` calls to start our server asynchronously. Check out our `}<a parentName="p" {...{
          "href": "/docs/apollo-server/getting-started#step-2-install-dependencies"
        }}>{`Getting Started`}</a>{` guide to see how we configured our project to support this.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      