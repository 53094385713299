import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpansionPanel = makeShortcode("ExpansionPanel");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ExpansionPanel title="Carthage Run Script" mdxType="ExpansionPanel">
      <p>{`The scripts and binaries that you need to generate code are included in the `}<inlineCode parentName="p">{`Carthage/Checkouts`}</inlineCode>{` folder. If this folder is not checked into version control, all developers on a team (and your CI machine) need to run `}<inlineCode parentName="p">{`carthage checkout`}</inlineCode>{` when changes are made to the version to ensure they have the correct underlying binaries and scripts.`}</p>
      <p>{`Team members can then use this build script:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`# Don't run this during index builds
if [ $ACTION = "indexbuild" ]; then exit 0; fi

SCRIPT_PATH="\${SRCROOT}/Carthage/Checkouts/apollo-ios/scripts"
cd "\${SRCROOT}/\${TARGET_NAME}"
"\${SCRIPT_PATH}"/run-bundled-codegen.sh codegen:generate --target=swift --includes=./**/*.graphql --localSchemaFile="schema.json" API.swift
`}</code></pre>
    </ExpansionPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      