import SPMXcodeInstallationPanel from "../../../gatsby-source-git/apollographql/apollo-ios-dev/ios/docs/shared/spm-xcode-installation-panel.mdx";
import SPMPackageInstallationPanel from "../../../gatsby-source-git/apollographql/apollo-ios-dev/ios/docs/shared/spm-package-installation-panel.mdx";
import PodsInstallationPanel from "../../../gatsby-source-git/apollographql/apollo-ios-dev/ios/docs/shared/pods-installation-panel.mdx";
import SetupCodegen from "../../../gatsby-source-git/apollographql/apollo-ios-dev/ios/docs/shared/setup-codegen/combined.mdx";
import * as React from 'react';
export default {
  SPMXcodeInstallationPanel,
  SPMPackageInstallationPanel,
  PodsInstallationPanel,
  SetupCodegen,
  React
};