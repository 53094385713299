import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Option`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`subscription`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DocumentNode`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A GraphQL subscription document parsed into an AST by `}<inlineCode parentName="td">{`graphql-tag`}</inlineCode>{`. `}<strong parentName="td">{`Optional`}</strong>{` for the `}<inlineCode parentName="td">{`useSubscription`}</inlineCode>{` Hook since the subscription can be passed in as the first parameter to the Hook. `}<strong parentName="td">{`Required`}</strong>{` for the `}<inlineCode parentName="td">{`Subscription`}</inlineCode>{` component.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`variables`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`{ `}{`[key: string]`}{`: any }`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An object containing all of the variables your subscription needs to execute`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`shouldResubscribe`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Determines if your subscription should be unsubscribed and subscribed again`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`skip`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If `}<inlineCode parentName="td">{`skip`}</inlineCode>{` is `}<inlineCode parentName="td">{`true`}</inlineCode>{`, the subscription will be skipped entirely`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onSubscriptionData`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(options: OnSubscriptionDataOptions`}{`<`}{`TData`}{`>`}{`) => any`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allows the registration of a callback function, that will be triggered each time the `}<inlineCode parentName="td">{`useSubscription`}</inlineCode>{` Hook / `}<inlineCode parentName="td">{`Subscription`}</inlineCode>{` component receives data. The callback `}<inlineCode parentName="td">{`options`}</inlineCode>{` object param consists of the current Apollo Client instance in `}<inlineCode parentName="td">{`client`}</inlineCode>{`, and the received subscription data in `}<inlineCode parentName="td">{`subscriptionData`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`fetchPolicy`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FetchPolicy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`How you want your component to interact with the Apollo cache. Defaults to "cache-first".`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`client`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ApolloClient`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An `}<inlineCode parentName="td">{`ApolloClient`}</inlineCode>{` instance. By default `}<inlineCode parentName="td">{`useSubscription`}</inlineCode>{` / `}<inlineCode parentName="td">{`Subscription`}</inlineCode>{` uses the client passed down via context, but a different client can be passed in.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      