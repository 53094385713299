import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Keep in mind that the amount of telemetry you add can impact your router's performance.`}</p>
    <ul>
      <li parentName="ul">{`Custom metrics, events, and attributes consume more processing resources than standard metrics. Adding too many (standard or custom) can slow your router down.`}</li>
      <li parentName="ul">{`Configurations such as `}<inlineCode parentName="li">{`events.*.request|error|response`}</inlineCode>{` that produce output for all router lifecycle services should only be used for development or debugging, not for production.`}</li>
    </ul>
    <p>{`For properly logged telemetry, you should use a log verbosity of `}<inlineCode parentName="p">{`info`}</inlineCode>{`. Set the values of `}<inlineCode parentName="p">{`RUST_LOG`}</inlineCode>{` or `}<inlineCode parentName="p">{`APOLLO_ROUTER_LOG`}</inlineCode>{` environment variables and the `}<inlineCode parentName="p">{`--log`}</inlineCode>{` CLI option to `}<inlineCode parentName="p">{`info`}</inlineCode>{`. Using less verbose logging, such as `}<inlineCode parentName="p">{`error`}</inlineCode>{`, can cause some attributes to be dropped.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      